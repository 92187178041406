import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: ""
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  class: "staking"
};
import { lpAbout } from '@/http';
import { computed, ref } from 'vue';
import { useUserStore } from '@/stores/user';
export default {
  __name: 'liquidity',
  setup(__props) {
    const lpAboutHtml = ref('');
    const userStore = useUserStore();
    const lang = computed(() => userStore.lang);
    const titleKey = computed(() => {
      return {
        'en_US': 'info_pc',
        'fr_FR': 'info_fr_pc',
        'es_ES': 'info_sp_pc',
        'it_IT': 'info_it_pc',
        'vi': 'info_vt_pc'
      }[lang.value];
    });
    const _lpAbout = async () => {
      const {
        data
      } = await lpAbout();
      lpAboutHtml.value = data;
    };
    _lpAbout();
    return (_ctx, _cache) => {
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "staking-content",
        innerHTML: lpAboutHtml.value[titleKey.value]
      }, null, 8, _hoisted_2), _cache[0] || (_cache[0] = _createElementVNode("div", {
        style: {
          "height": "40px"
        }
      }, null, -1)), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_button, {
        class: "buttons",
        color: "#56F9AA",
        block: "",
        round: "",
        to: "/liquidity_pool"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('participate_in_staking')), 1)]),
        _: 1
      })])]);
    };
  }
};